/* main */
body {
  background-color: white !important;
  font-family: "Lato", sans-serif !important;
  font-weight: 500 !important;
  overflow-y: hidden !important;
}

.main-app {
  height: 100vh;
}

.home-page {
  height: 90%;
}

.welcome-image {
  width: 68%;
}

h5 {
  font-family: "Raleway", sans-serif !important;
  font-weight: 900 !important;
  font-size: 1.4em !important;
}

/* h6 {
  font-weight: 700 !important;
  font-size: 1.1em !important;
} */

h4 {
  font-family: "Raleway", sans-serif !important;
  font-weight: 900 !important;
  font-size: 1.5em !important;
}

label {
  font-family: "Raleway", sans-serif !important;
  font-weight: 700 !important;
}

a {
  color: inherit !important;
}

a:hover {
  text-decoration: none !important;
}

body .history {
  overflow-y: auto;
}

/* bottom nav */
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #383837 !important;
  margin-top: 70px;
}

.bottom-nav a:hover {
  text-decoration: none;
}

.inactive {
  color: #383837 !important;
}

.active {
  color: #0693c8 !important;
  text-decoration: none !important;
}

/* settings page */
.custom-control-label::before {
  background-color: white !important;
  border: white solid 1px !important;
}

.custom-control-label::after {
  background-color: #0693c8 !important;
  border: white solid 1px !important;
}

.toggle {
  font-family: "Raleway", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.1em !important;
}

.sm-text {
  font-size: 0.8rem !important;
}

.settingsLinks {
  padding-top: 5px;
}

.notes {
  font-size: 0.8em !important;
}

/* device settings icon */
.custom-icon {
  height: 45px;
}

/* about page */
.back {
  font-family: "Lato", sans-serif !important;
  font-weight: 700 !important;
}

/* update page */
.btn-primary {
  background-color: #3d5ba9 !important;
  border-color: #3d5ba9 !important;
  margin-bottom: 30px;
  font-weight: 700 !important;
}

.btn-primary:hover {
  background-color: #5884f5 !important;
  border-color: #5884f5 !important;
}

/* Settings Slider */
.active-slider .custom-control-label::before {
  background-color: #0693c8 !important;
  border: #0693c8 !important;
}

.active-slider .custom-control-label::after {
  background-color: white !important;
  border: white !important;
}

/* history page */
.eventHistory {
  font-weight: 700 !important;
  font-size: 1.2em;
}

.dateHistory {
  font-size: 1em;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.footer-icon {
  font-size: 1rem;
  font-weight: 600;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.vh-95 {
  height: 95vh;
}
